:root {
  /* Base Colors */
  --sage-light: #F2F5F3;    /* Warm light background, replacing misty-blue */
  --sage-mid: #8FA898;      /* Medium tone, replacing blue-gray */
  --forest-green: #2A5A45;  /* Main accent, replacing midnight-blue */
  --deep-green: #1C3B2E;    /* Dark tone, replacing dark-blue */
  
  /* Supporting Colors */
  --warm-white: #F8FAF8;    /* For cards, very subtle green tint */
  --accent-green: #3E7B64;  /* Brighter accent for interactive elements */
  --text-dark: #1F3B31;     /* For headings */
  --text-light: #526B61;    /* For body text */
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: var(--sage-light);
  color: var(--text-dark);
}